.NavLogo{
    height: 3rem;
    width: 3rem;
    display: flex;
    flex-direction: row;
  
    /* align-items: center; */
}
.nav-logo-container{
    /* border: 2px solid green; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* justify-content: space-around; */
}
.contact-text{
    font-size: 0.85rem;
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nav-logo-container > p {
    color: green;
    font-size: 1.5rem;
}


