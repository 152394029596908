.testimonialSectionWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f9f9f9;
    overflow: hidden; /* Ensure no overflow */
  }
  
  .testimonialSectionTop {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .primarySubheading {
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  .primaryHeading {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  .primaryText {
    font-size: 1rem;
    color: #666;
    margin-bottom: 30px;
   
  }
  
  .slick-slider {
    width: 80%; /* Reduce the width of the slider */
    max-width: 800px;
    margin: 0 auto; /* Center the slider */
  }
  
  .testimonialCard {
    background-color: #fff;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    margin: 0 auto; /* Center the testimonial card */
  }
  
  .testimonialCard p {
    font-size: 1rem;
    color: #444;
    margin-bottom: 20px;
  }
  
  .testimonialsStarsContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
  
  .testimonialsStarsContainer svg {
    color: #ffcc00;
    margin: 0 5px;
  }
  
  h2 {
    font-size: 1.25rem;
    color: #333;
    margin-top: 10px;
  }
  