.cards {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.individualcards {
  border: 0.5px solid rgba(0, 0, 0, 0.7);
  margin-top: 20px;
  width: 18rem; /* Ensure consistent card width */
}

.primary-subheading{
  display: flex;
  justify-content: center;
}

.EventImgContainer {
  align-items: center;
  justify-content: center;
  display: flex; /* Make sure the image is centered */
}

.EventImg {
  height: 12rem;
  width: 12rem;
}

.individualcards:hover {
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  border: none;
}

.cardstitles {
  font-weight: bolder;
  text-align: center;
}

/* TennisCard.css */
.detailscard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
 
  margin: 20px auto;
}

.details-content {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
}

.align-image{
  max-width: 1200px; 
}

/* .details {
  flex: 1;
} */

.details p {
  margin-bottom: 8px;
}

.details strong {
  margin-right: 8px;
}



.details-image img {
  width: 100%; /* Adjust as necessary */
  height: auto; /* Maintain aspect ratio */
}



.campheadpart {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
}

.camplists {
  
  font-size: 20px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}

@media screen and (max-width: 600px) {
  .campheadpart {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media screen and (max-width: 480px) {
  .campheadpart {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column; /* Change to column for better stacking */
    justify-content: center;
    text-align: center;
    margin-left: 0px;
    margin-right: 0px;
  }

  .detailscard {
    flex-direction: column; /* Stack the content vertically */
    align-items: center; /* Center align the content */
  }

  .details-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }

  .details-image img {
    width: 60%; /* Adjust width for mobile view */
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 768px) { /* Tablet view */
  .details-image img {
    width: 80%; /* Adjust width for tablet view */
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .details-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
}

