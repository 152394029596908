/* .footer {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #cdf4d7;
    text-align: center;
    padding: 20px 0 10px;
    z-index: 1000;
    background-color: red;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  
  @media screen and (max-width: 560px){
    .footer{
      background-color: blue;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
    }
  } */
/* styles.css */
.footerlogo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  @media screen and (max-width: 560px) {
    .footerlogo {
      display: none;
    }
  }
  