* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Reem Kufi", sans-serif !important;
}

img {
  max-width: 100%;
  height: auto;
}

.App {
  min-height: 100vh;
  width: 100vw;
  margin: 0rem;
  padding: 0rem 2rem;
}

body {
  max-width: 100%;
  overflow-x: hidden;
  background-color: #f6f6f6;
}

nav {
  display: flex;
  justify-content: space-between;
  min-height: 70px;
  width: 95%;
  position: sticky;
  top: 0;
  background-color: #cdf4d7;
  z-index: 1000;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  margin: 1rem auto;
  padding: 0rem 1rem;
  align-items: center;
}

.navbar-menu-container {
  display: none;
}

.navbar-links-container {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-links-container a {
  text-decoration: none;
  color: black;
  font-size: 1rem;
  font-weight: 600;
}

.card-red {
  background-color: #ffdddd;
}

.card-orange {
  background-color: #ffe5cc;
}

.card-green {
  background-color: #ddffdd;
}

.card-blue {
  background-color: #ddefff;
}

.card-body p {
  margin: 0;
}

.card-deck {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
}

@media (min-width: 768px) {
  .card-deck {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
}

@media (min-width: 992px) {
  .card-deck {
    grid-template-columns: repeat(4, 1fr);
  }
}

.primaryjoin-button {
  padding: 0.7rem 1.3rem;
  background-color: rgb(29, 151, 51);
  color: white;
  outline: none;
  border: none;
  border-radius: 0.7rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
  width: 20%;
}

.primaryjoin-button:hover {
  background-color: white;
  color: rgb(29, 151, 51);
}

.primary-button {
  padding: 0.7rem 1.3rem;
  background-color: rgb(29, 151, 51);
  color: white;
  outline: none;
  border: none;
  border-radius: 0.7rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}

.primary-button:hover {
  background-color: white;
  color: rgb(29, 151, 51);
}

.navbar-cart-icon {
  font-size: 1.15rem;
}

.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}

.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 3rem;
}

.home-bannerImage-container {
  position: absolute;
  z-index: -2;
  max-width: 700px;
  display: flex;
  float: right;
  border: 2px solid red;
}

.home-image-section {
  max-width: 600px;
  flex: 1;
}

.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.primary-heading {
  font-size: 2rem;
  color: #4c4c4c;
  max-width: 600px;
}

.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  color: #6a6a6a;
}

.secondary-button {
  padding: 1rem 2.5rem;
  background-color: rgb(29, 125, 51);
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}

.secondary-button:hover {
  background-color: #e48f0f;
}

.displayfles {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.about-section-container {
  margin-top: 2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding-bottom: 35px;
  width: 93%;
  margin: auto;
}

@media screen and (max-width: 560px) {
  .about-section-container {
    margin-top: 2rem;
  }
}

.about-background-image-container {
  position: absolute;
  right: -100px;
  z-index: -2;
}

.about-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
}

.about-section-text-container {
  flex: 1;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.primary-subheading {
  font-weight: 700;
  color: rgba(29, 151, 51);
  font-size: 3rem;
}

.backGroundRound {
  height: 40rem;
  width: 40rem;
  opacity: 0.6;
  border-radius: 50%;
  background-color: rgba(29, 151, 51, 0.5);
  filter: blur(5rem);
  display: inline-block;
}

.about-buttons-container {
  display: flex;
}

.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}

.contact-page-wrapper h1 {
  max-width: 900px !important;
}

.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
}

.contact-form-container {
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
}

.contact-form-container input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}

.footer-wrapper {
  margin: 6rem 0;
  display: flex;
  margin-top: 10rem;
}

.footer-logo-container {
  max-width: 110px;
}

.footer-icons {
  margin-top: 2.5rem;
}

.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 1.25rem;
  color: #585858;
}

.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
}

.footer-section-columns span {
  margin: 0.25rem 0;
  font-weight: 600;
  color: #4c4c4c;
  cursor: pointer;
}

.testimonial-section-bottom {
  margin-top: 2rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 450px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
  border: 0.5px solid rgba(0, 0, 0, 0.7);
}

.testimonial-section-bottom:hover {
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
  border: none;
}

.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0;
}

.testimonials-stars-container svg {
  margin: 0 0.25rem;
  font-size: 1.5rem;
  color: rgb(29, 125, 51);
}

.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}

.work-section-wrapper {
  margin-top: 3rem;
}

.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}

.work-section-top h1 {
  max-width: 700px !important;
}

.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.work-section-info {
  width: 290px;
  min-height: 350px;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  margin: 1rem 2rem;
  border: 0.5px solid rgba(0, 0, 0, 0.7);
}

.work-section-info:hover {
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
  border: none;
}

.work-section-info h2 {
  margin: 1rem 0;
}

.work-section-bottom {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}

@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
    max-width: 600px;
  }
}

@media (max-width: 800px) {
  .nav-logo-container {
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }
  .home-bannerImage-container {
    max-width: 450px;
  }
  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .about-section-container {
    flex-direction: column;
  }
  .home-image-section,
  .about-section-image-container {
    width: 100%;
    max-width: 400px;
  }
  .primary-heading {
    text-align: center;
    max-width: 90%;
  }
  .primary-text {
    text-align: center;
    max-width: 80%;
  }
  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  .about-section-container {
    margin-top: 2rem !important;
  }
  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .watch-video-button {
    margin-left: 0 !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .about-section-image-container {
    margin-right: 0 !important;
  }
  .work-section-wrapper {
    margin-top: 5rem !important;
  }
  .work-section-bottom {
    margin-top: 1rem !important;
  }
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }
  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }
  .footer-wrapper {
    flex-direction: column;
  }
  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;
  }
  .footer-section-columns {
    margin: 1rem 0;
  }
  .App {
    max-width: 100vw;
    padding: 0 0.3rem;
  }
}

@media (max-width: 600px) {
  .home-bannerImage-container,
  .about-background-image-container {
    display: none;
  }
}

footer {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #cdf4d7;
  text-align: center;
  padding: 20px 0 10px;
  z-index: 1000;
}

footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: rgb(29, 125, 51);
}

.social-icons {
  display: flex;
  justify-content: center;
  padding: 3px 0;
  position: relative;
  z-index: 1;
  flex-wrap: wrap;
  margin-bottom: -40px;
}

.social-icons a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 5px;
  background-color: #fff;
  border-radius: 50%;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  position: relative;
  overflow: hidden;
}

.social-icons a::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #ff6b6b, #4ecdc4);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  z-index: -1;
}

.social-icons a:hover::before {
  top: 0;
}

.social-icons a:hover {
  transform: translateY(-5px) rotate(5deg);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
}

.social-icons svg {
  width: 20px;
  height: 20px;
  fill: #333;
  transition: all 0.4s ease;
}

.social-icons a:hover svg {
  fill: #fff;
  transform: scale(1.2);
}

.copyright {
  background-color: #cdf4d7;
  color: #333;
  font-size: 12px;
  padding: 10px;
  margin-top: 10px;
  position: relative;
}

.copyright::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 1px;
  background: rgb(29, 125, 51);
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.testimonials-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 2rem 0;
}

@media (max-width: 768px) {
  .testimonials-container {
    flex-direction: column;
    align-items: center;
  }
  footer {
    padding: 15px 0 5px;
  }
  .social-icons a {
    width: 35px;
    height: 35px;
    margin: 3px;
  }
  .social-icons svg {
    width: 18px;
    height: 18px;
  }
  .copyright {
    font-size: 10px;
    padding: 8px;
  }
}
@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

.social-icons a {
  animation: float 3s ease-in-out infinite;
}

.social-icons a:nth-child(2) { animation-delay: 0.2s; }
.social-icons a:nth-child(3) { animation-delay: 0.4s; }
.social-icons a:nth-child(4) { animation-delay: 0.6s; }