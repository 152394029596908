.popup-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
  }
  .dialog-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
  }
  .qr-code-image {
    max-width: 400px;
    height: auto;
    padding: 20px;
    width: 100%;
  }
  @media (max-width: 430px) {
    .qr-code-image {
      max-width: calc(100% - 40px); 
      padding: 10px;
    }
  }