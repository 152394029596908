.about-section-container {
    padding: 20px;
  }
  
  .displayfles {
    display: flex;
    justify-content: space-between;
  }
  
  .about-section-text-container {
    max-width: 600px;
  }
  
  .primaryjoin-button {
    /* background-color: #000; */
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .cards-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .card {
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .card-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }
  
  .card-image {
    width: 50%; /* Adjusts the image size to 50% of its container */
    height: auto;
    /* border-radius: 50%; */
  }
  
  .card-content {
    padding: 10px;
    text-align: center;
  }
  
  .card-text {
    margin: 10px 0;
  }
  
  .card-name {
    font-weight: bold;
  }
  
  .primary-text{
    font-size: 0.51rem;
    border: 0;
    color: rgb(29, 151, 51);
  }
  .primary-text:hover{
    border: 0;
    background: rgba(29, 151, 51, 0.126);
    color: rgb(29, 151, 51);
  }
  /* Coaches Sections Names */
  .truncate {
    height: 100px; /* Fixed height */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
  }
  
  .show-more {
    height: auto;
  }
  
  .card-img-top{
    max-height: 250px;
  }


  .video-container {
    width: 100%;
  }
  
  #vid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 16px;
  }
  
  @media (max-width: 1024px) {
    #vid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    #vid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  