.carousels {
    border-radius: 10px;
    width: 100%;
    height: 50vh;
    overflow: hidden;
}

.carousels img {
    width: 100%;
    height: 50vh;
    object-fit: cover;  /* Ensures images cover the container */
}

@media screen and (max-width: 480px) {
    .carousels {
        height: 25vh;
    }
    .carousels img {
        height: 25vh;
        width: 100%;
        object-fit: fill;
        object-position: center;
    }
}
