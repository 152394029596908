.contact-page-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  background-color: #f9f9f9;
}

.contact-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.contact-form-container {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.primary-heading {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: #333;
}

.secondary-button {
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  border: none;
  box-shadow: none;
}

.contactsideheadings {
  font-weight: bold;
  text-align: center;
}

.submitbutton {
  background-color: rgb(11, 96, 11) !important;
  width: 100% !important;
  color: white !important;
  border-radius: 7px !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.submitbutton:hover {
  background-color: green !important;
}

.contactheading {
  
  font-weight: bold;
  color: rgb(11, 96, 11);
}

.map-container {
    flex: 1 1;
    height: 592px;
    margin: 20px;
}

@media screen and (max-width: 768px) {
  .contact-content {
    flex-direction: column;
    align-items: center;
  }

  .map-container {
    margin-left: 0;
    margin-top: 20px;
    width: 100%; /* Ensure the map takes full width on smaller screens */
    height: 300px; /* Adjust height for smaller screens */
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 10px; /* Adjust spacing as needed */
}

.c1 {
  margin-left: 10px;
  margin-right: 10px; /* Adjust this value to control the space between the label and the number */
}

.highlight-label {
  font-weight: bold;
  color: green; /* Adjust color as desired */
}

.center-text {
  margin: 0; /* Remove default margins */
}

/* Number arrows remove */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}